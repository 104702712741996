@import '../../utils/utils';

.header {
  background-color: var(--surfaceAlt);
  z-index: 1;
  width: 100%;

  .header-inner {
    align-items: center;
    display: flex;
    max-width: 1330px;
    margin: 0px auto;
    padding: 15px;
    justify-content: space-between;
  }

  .brand {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: var(--contrastLow);
  }

  .brand-logo {
    width: 2rem;
    height: 2rem;
    margin-right: 0.25rem;
  }

  .brand-name {
    font-size: 20px;
    font-weight: 500;
    align-self: baseline;
    color: white;
  }

  .brand-labs {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 2.5px;
    margin-left: 10px;
  }

  .brand-slogan {
    border-left: 1px solid var(--secondaryAlt);
    margin-left: 0.5em;
    padding-left: 0.5em;
  }

  button.share {
    @include button-reset;
    margin-left: auto;
    z-index: 2;
    @media ($mq-desktop) {
      margin-right: 20px;
    }
  }
}
