@import '../../utils/utils';

.sidebar {
  position: relative;
  .sidebar-toggle {
    @include button-reset;
    position: relative;
    z-index: 8;
    height: 62px;
    width: 54px;
    margin-bottom: -2em;
    &, svg {
      transition: all .25s;
    }
    &:hover svg {
      transform: rotate(180deg);
    }

    @media ($mq-mobile) {
      position: absolute;
      top: 0;
      right: 0;
      svg {
        transform: rotate(90deg);
      }
      &:hover svg {
        transform: rotate(180deg);
      }
    }
  }
  &.collapsed {
    .sidebar-toggle {
      svg {
        transform: rotate(270deg);
      }
      @media ($mq-desktop) {
        width: 40px;
        translate: -100%;
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}