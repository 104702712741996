@import '../../utils/utils';

.upload-page {
  width: 100%;
  height: 100%;
  @media ($mq-desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .intro,
  .upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px clamp(20px, 60px, 4vw);
    max-width: 100%;
    .container {
      width: 600px;
      max-width: 100%;
      @media ($mq-desktop) {
        width: auto;
        max-width: 600px;
      }
    }
  }

  .intro {
    h1 {
      position: relative;
      font-size: 64px;
      font-size: clamp(36px, 64px, 4.4vw);
      line-height: 1;
      margin: 0 0 40px;
    }
    img {
      max-width: 100%;
      position: relative;
    }

    @media ($mq-desktop) {
      color: var(--surface);
      background-color: var(--primary);
      .container {
        align-self: flex-end;
        position: relative;
        &::before {
          content: url('./circle.svg');
          position: absolute;
          bottom: -50px;
          left: 15px;
        }
      }
      h1::before {
        content: url('./dots.svg');
        position: absolute;
        top: -75px;
        left: -15px;
      }
      h1::after {
        content: url('./dots-square.svg');
        position: absolute;
        bottom: -80px;
        right: -28px;
      }
    }

    @media ($mq-mobile) {
      h3,
      img {
        display: none;
      }
    }
  }

  .upload {
    @media ($mq-desktop) {
      .container {
        align-self: flex-start;
      }
    }
    @media ($mq-mobile) {
      h4.try {
        display: none;
      }
    }
    h4 {
      align-self: flex-start;
      font-size: 24px;
      font-weight: normal;
      display: block;
    }
    .dropzone {
      box-sizing: border-box;
      border: 4px dashed var(--secondaryAlt);
      background-color: var(--surface);
      color: var(--contrastLow);
      border-radius: 5px;
      padding: 40px 20px;
      text-align: center;
      transition: all 0.2s ease-in-out;
      font-family: var(--inter);
      font-size: 16px;
      line-height: 1.5;
      width: 100%;
      aspect-ratio: 600/300;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .error {
        font-size: 80%;
        margin-top: 0;
        color: #ff5722;
      }
    }

    .dropzone:hover {
      border-color: var(--contrastLow);
    }

    .dropzone.active {
      border-color: var(--contrastLow);
      background-color: var(--surfaceAlt);
    }

    .samples {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        max-width: 180px;
        background: none;
        border: none;
        color: var(--contrastLow);
        font-weight: bold;
        cursor: pointer;
        box-sizing: content-box;
      }
      img {
        max-width: 100%;
        height: auto;
        transition: all 0.1s ease-in-out;
        aspect-ratio: 180 / 120;
        &:hover {
          filter: contrast(160%);
        }
      }
    }
  }
}
