$mq-desktop: "min-width: 877px";
$mq-mobile: "max-width: 876px";

@mixin button-reset {
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}