@import '../../utils/utils';

.feedback {
  padding: 0 24px 84px;
  font-family: var(--inter);

  h3 {
    margin-top: 2em;
    font-weight: 500;
    @media ($mq-mobile) {
      margin: 0;
      padding: 1.1em 0;
    }
  }

  button {
    @include button-reset;
  }

  .ratings {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    font-size: 12px;

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 0 11px 6px;
      button {
        $size: 24px;
        width: $size;
        height: $size;
        border-radius: $size;
        line-height: $size;
        border: 1px solid var(--contrastLow);
        background-color: var(--surface);
        &:hover,
        &.active {
          color: var(--surface);
          border-color: var(--contrastLow);
          background-color: var(--contrastLow);
        }
      }
    }
    .labels {
      width: 100%;
      display: flex;
      justify-content: space-between;
      span {
        width: 70px;
        text-align: center;
      }
    }
  }

  .input {
    box-sizing: border-box;
    background: var(--surfaceAlt);
    width: 100%;
    border: 1px solid var(--secondaryAlt);
    border-radius: 6px;
    padding: 6px 8px;
    color: var(--contrastLow);
    font-family: var(--inter);
    &::placeholder {
      color: var(--contrastLow);
      opacity: 0.7;
    }
    &:placeholder-shown + button.send-feedback {
      pointer-events: none;
      opacity: 0.2;
      filter: saturate(0.5);
    }
  }
  textarea {
    min-height: 100px;
  }
  button.btn.send-feedback {
    position: relative;
    float: right;
    translate: -5px -120%;
    padding: 7px;
    transition: all 0.3s;
    opacity: 1;
    filter: saturate(1);
    svg {
      margin: 0;
    }
  }

  .email {
    position: relative;
    display: flex;
    input {
      line-height: 30px;
      &:invalid + button.send-email {
        pointer-events: none;
        opacity: 0.2;
        filter: saturate(0.5);
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 10px 0;
    }
  }

  .agree {
    font-size: 14px;
    display: flex;
    align-items: center;

    input {
      margin: 0;
      appearance: none;

      ~ .checked {
        display: none;
        color: var(--primary);
      }
      &:checked {
        ~ .checked {
          display: inline;
        }
        ~ .unchecked {
          display: none;
        }
      }
    }
    svg {
      margin-right: 0.5em;
    }
    span {
      display: inline-block;
    }
    a {
      color: var(--contrastLow);
      text-decoration: none;
      font-weight: 700;
    }
  }

  .actions {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
  }

  .sign-up {
    color: var(--contrastLow);
    text-decoration: none;
    margin: auto;
    display: block;
    width: fit-content;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 14px;
    line-height: 28px;
    padding: 25px 10px;
  }
}
