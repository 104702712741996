@import '../../utils/utils';

.modal-overlay {
  position: fixed;
  z-index: 9;
  inset: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(10, 12, 15, 0.8);
}
.modal {
  font-size: 14px;
  padding: 0 1.25rem;
  max-height: -webkit-fill-available;
  max-width: calc(100vw - 2rem);
  border-radius: 5px;
  background-color: var(--surfaceAlt);
  margin: 1rem;
  box-shadow: rgba(black, 0.29) 0px 2px 23px 7px;
  overflow-y: auto;

  button.modal-close {
    @include button-reset;
    float: right;
    margin: 1rem 0;
  }
}