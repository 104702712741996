@import '../../utils/utils';

.footer {
  font-family: var(--inter);
  background-color: var(--surfaceAlt);
  z-index: 1;
  width: 100%;

  .footer-inner {
    align-items: center;
    display: flex;
    max-width: 1080px;
    margin: 0px auto;
    padding: 15px;
    justify-content: space-between;
  }

  button {
    @include button-reset;
  }

  a, button {
    color: var(--primary);
    font-weight: 600;
    text-decoration: none;
  }

  .label {
    @media ($mq-mobile) {
      display: none;
    }
  }
}
