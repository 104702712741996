.canvas {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  div {
    max-width: 100%;
  }
  canvas {
    max-width: 100%;
    height: auto !important;
    inset: 0;
    margin: auto !important;
  }
}