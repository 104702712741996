@import '../../utils/utils';

:root {
  --primary: #0d9aff;
  --background: #090c0f;
  --surface: #1f242e;
  --surfaceAlt: #14181e;
  --contrastLow: #d1d6e0;
  --secondaryAlt: #535f7a;
  --sofia-pro: 'sofia-pro', sans-serif;
  --inter: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: var(--sofia-pro);
  color: var(--contrastLow);
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;

  .sidebar {
    background-color: var(--surface);
    transition: max-width 0.2s, max-height 0.2s;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    min-height: 100vh;
    max-width: 100%;
    transition: max-width 0.2s, min-height 0.2s;
  }

  @media ($mq-desktop) {
    flex-direction: row;

    .sidebar {
      max-width: 0;
      &:not(:empty) {
        max-width: 309px;
      }
      &.collapsed {
        max-width: 0;
      }
    }
    .main {
      &:has(+ .sidebar:not(:empty, .collapsed)) {
        max-width: calc(100% - 309px);
      }
    }
  }

  @media ($mq-mobile) {
    .main {
      min-height: 100vh;
      &:has(+ .sidebar:not(:empty)) {
        min-height: calc(100vh - 62px);
      }
      &:has(+ .sidebar:not(:empty, .collapsed)) {
        min-height: 50vh;
      }
    }
  }

  .content {
    width: 100%;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  // Buttons
  .btn {
    background-color: var(--primary);
    color: var(--background);
    font-size: 14px;
    padding: 10px 14px;
    font-weight: 700;
    text-decoration: none;
    border-radius: 2px;
    transition: all 0.25s;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
      filter: brightness(1.1);
    }

    &[disabled] {
      opacity: 0.5;
    }

    svg {
      vertical-align: middle;
      margin-right: 0.5em;
      margin-left: -0.25em;
    }

    &.inverted {
      background-color: transparent;
      color: var(--primary);
    }

    &.light {
      color: var(--contrastLow);
    }
  }
}
