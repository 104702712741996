.progress {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 240px;
    max-width: 100%;
  }

  p {
    font-size: 24px;
    margin-top: 24px;
  }
}